import React from 'react';
import bff, { isCancel } from 'services/bff';
import config from 'config';
import constants, { fieldWidthType } from 'services/constants';
import errors from 'services/errors';
import moment from 'moment';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import { holdingHelper } from 'services/holdingHelper';
import Button from 'components/base/button/button';
import { cloneDeep, isArray, isEqual, mergeWith } from 'lodash';
const sprintf = require('util').format;
const XLSX = require('xlsx');

const digitsRegex = /^[0-9]*$/;

const consolidateSeparator = '|';
const consolidateMessages = [
  {
    code: '3006',
    from: RegExp('Tag (.*) does not exist\\.*'),
    to1: 'Tag number %s was not recognised by the service and has been added.',
    to: 'Tag number %s were not recognised by the service and have been added.'
  },
  {
    code: '8031',
    from: RegExp('The tag number (.*) has been previously applied\\.*'),
    to1: 'Tag number %s has been previously applied.',
    to: 'Tag number %s have been previously applied.'
  }
];

const consolidateMessagesWith3Fields = [
  {
    code: '31395',
    from: RegExp('Tag number (.*) has been applied to the species (.*) which does not match the request species of (.*).\\.*'),
    to1: 'Tag number %s has been applied to the species %s which does not match the request species of %s.',
    to: 'Tag number %s have been applied to the species %s which do not match the request species of %s.'
  }
];

const messageMapping = [
  {
    from: RegExp('Tag(.*)does not exist\\.*'),
    to: 'Tag number$1was not recognised by the service and has been added'
  },
  {
    from: RegExp('The batch number(.*)has been added to the system by Request =\\s\\w+'),
    to: 'Batch number$1was not recognised by the service and has been added'
  },
  {
    from: RegExp('The animal(.*) has already been reported as killed. It cannot be used for OnFarmKill reporting\\.*'),
    to: 'Animal$1 has been reported as dead'
  },
  {
    code: 25,
    from: RegExp('The TrackingID(.*) has previously been used for the specified software key, user and request type.\\s+It is duplicate with Request_ID(.*).'),
    to: 'This submission has failed as it is a duplicate of a previously submitted request, request id$2.'
  }
];

const sortByProperty = (property, direction) => (a, b) => {
  const propA = !a[property] ? '' : a[property];
  const propB = !b[property] ? '' : b[property];

  if (direction === constants.sorting.ascending) {
    if (propA < propB) {
      return -1;
    }

    if (propA > propB) {
      return 1;
    }
  }

  if (direction === constants.sorting.descending) {
    if (propA > propB) {
      return -1;
    }

    if (propA < propB) {
      return 1;
    }
  }

  return 0;
};

const boRoles = ['backofficeagent'];
const rcvsRoles = ['rcvsvet'];

const helpers = {
  //pastOneYearFromToday -> Allows to select only past one year date from today, other dates are disable
  pastOneYearFromToday: {
    datePastOneYear: () => {
      const today = new Date();
      today.setFullYear(today.getFullYear() - 1);
      today.setDate(today.getDate() + 1);
      return helpers.date.formatYYYYMMDD(today);
    }
  },
  vetmaxDate: {
    todayDate: () => {
      const today = new Date();
      return helpers.date.formatYYYYMMDD(today);
    }
  },
  vetAttestationNumber: {
    generateVan: (rcvsNo, cph, attestationDate) => {
      const expireDate = new Date(attestationDate);
      expireDate.setFullYear(expireDate.getFullYear() + 1);
      expireDate.setDate(expireDate.getDate() - 1);
      const formattedDate = moment(expireDate).format('MMYY');
      return `${rcvsNo} ${cph} ${formattedDate}`;
    }
  },
  getProcessingCheckStatus: (flagData, flagId1, flagId2 = 0, flagId3 = 0, flagId4 = 0, flagId5 = 0, flagId6 = 0) => {
    const filterFlagData = flagData.filter((item) => ((item.id === flagId1) || (item.id === flagId2) || (item.id === flagId3) || (item.id === flagId4) || (item.id === flagId5) || (item.id === flagId6)) && item.value === true);
    return filterFlagData.length > 0;
  },
  restrictBackFromVetDeclaration: () => {
    if (storeService.session.get.restrictBackFlow()) {
      storeService.cookie.removeAll.all();
      storeService.local.removeAll.all();
      storeService.session.removeAll.all();
    }
  },
  restrictBackFromVetSummary: () => {
    if (storeService.session.get.restrictSummaryBack()) {
      storeService.cookie.removeAll.all();
      storeService.local.removeAll.all();
      storeService.session.removeAll.all();
    }
  },
  address: {
    isInline: (format) => format === constants.address.format.inline,

    isKeeper: (format) => format === constants.address.format.keeper,

    isLine: (format) => format === constants.address.format.line,

    isNoNameFound: (name) => name === constants.address.status.NO_NAME,

    isNone: (format) => format === constants.address.format.none,

    isOnlyAddress: (format) => format === constants.address.format.onlyAddress,

    isShort: (format) => format === constants.address.format.short,

    isStatusError: (status) => status && status === constants.address.status.ERROR,

    isStatusError1: (status) => status && status === constants.address.status.BFF_ERROR1,

    isStatusError2: (status) => status && status === constants.address.status.BFF_ERROR2,

    isStatusFetch: (status) => status && status === constants.address.status.FETCH,

    isStatusNoAddress: (status) => status && status === constants.address.status.NO_ADDRESS,

    isStatusNotFound: (status) => status && status === constants.address.status.NOT_FOUND,

    isStatusFoundWithoutAddress: (status) => status && status === constants.address.status.FOUND_WITHOUT_ADDRESS,

    isUnknown: (address) => !address || address.toLowerCase() === config.UNKNOWN,

    nameStatus: (value) => (!value || helpers.address.isStatusError1(value.lastName) || helpers.address.isStatusError2(value.statusText))
      ? constants.address.status.FOUND_WITHOUT_ADDRESS
      : constants.address.status.NOT_FOUND,

    withAddress: (value) => !helpers.address.isStatusNoAddress(value) &&
      !helpers.address.isStatusNotFound(value) &&
      !helpers.address.isStatusFoundWithoutAddress(value),

    withErrorAddress: (value) => helpers.address.isStatusNoAddress(value) ||
      helpers.address.isStatusNotFound(value) ||
      helpers.address.isStatusFoundWithoutAddress(value),

    format: {
      nameFull: (address) => {
        if (!address) {
          return address;
        }
        let result = [address.title, address.firstName, address.lastName].filter(Boolean).join(' ');
        if (result?.length === 0) {
          result = address.keeper ? [address.keeper.firstName, address.keeper.lastName].filter(Boolean).join(' ') : [];
          if (result?.length === 0) {
            result = constants.address.status.NO_NAME;
          }
        }
        return helpers.address.isStatusError1(result) || helpers.address.isStatusError2(result) ? '' : result;
      },

      typeFull: (address) => {
        if (!address || typeof address !== 'object') {
          return constants.address.status.NO_TYPE;
        }
        let result = '';
        if (!helpers.address.isStatusError1(address.lastName) && helpers.address.isStatusError2(address.statusText)) {
          result = address.holdingType && address.holdingType.fullName
            ? address.holdingType.fullName
            : constants.address.status.NO_TYPE;
        }
        return result;
      },

      addressFull: (address) => {
        if (!address || typeof address !== 'object') {
          return [constants.address.status.NOT_FOUND];
        }
        const result = [address.propertyName, address.address1, address.address2, address.town, address.county, address.postCode].filter(Boolean);
        if (result?.length === 0) {
          result.push(helpers.address.nameStatus(address) ? constants.address.status.NO_ADDRESS : constants.address.status.NOT_FOUND);
        }
        return result;
      },

      addressShort: (address) => {
        if (!address || typeof address !== 'object') {
          return [constants.address.status.NOT_FOUND];
        }
        const result = [address.propertyName, address.address1, address.town, address.postCode].filter(Boolean);
        if (result?.length === 0) {
          result.push(helpers.address.nameStatus(address) ? constants.address.status.NO_ADDRESS : constants.address.status.NOT_FOUND);
        }
        return result;
      },

      addressLine: (address) => {
        if (!address || typeof address !== 'object') {
          return [constants.address.status.NOT_FOUND];
        }
        const result = [];
        const formatLine = helpers.address.formatLine(address);
        if (formatLine?.length === 0) {
          result.push(helpers.address.nameStatus(address) ? constants.address.status.NO_ADDRESS : constants.address.status.NOT_FOUND);
        } else {
          result.push(formatLine);
        }
        return result;
      },

      addressKeeper: (address) => {
        const result = helpers.address.formatAddress(address);
        if (result?.length === 0) {
          result.push(helpers.address.nameStatus(address));
        }
        return result;
      },

      addressWithoutHoldingName: (address) => {
        const result = [];
        const formatLine = helpers.address.formatOnlyAddressInline(address);
        if (formatLine?.length > 0) {
          result.push(formatLine);
        } else {
          result.push('-');
        }
        return result;
      },

      addressOnly: (value) => helpers.address.isStatusError(value) ||
        helpers.address.isStatusNoAddress(value) ||
        helpers.address.isStatusFetch(value)
    },

    formatAddress: (address) => address
      ? [
        address.propertyName,
        address.address1,
        address.address2,
        address.town,
        address.county,
        address.postCode
      ].filter(Boolean)
      : [],

    formatAddressWithoutHoldingName: (address) => address
      ? [
        address.address1,
        address.address2,
        address.town,
        address.county,
        address.postCode
      ].filter(Boolean)
      : [],

    formatLine: (address) => helpers.address.formatAddress(address).join(', '),

    formatOnlyAddressInline: (address) => helpers.address.formatAddressWithoutHoldingName(address).join(', ')
  },

  animal: {
    ageToDisplay: (startDate, endDate) => {
      if (startDate && endDate) {
        return helpers.date.withSameCalendarDay(startDate, endDate) ? helpers.get.age(startDate) : helpers.date.ageInYears(startDate);
      }

      return '-';
    },

    addDobAndAgeForDisplay: (inputData) => {
      if (!inputData) {
        return [];
      }

      return inputData.map((animal) => {
        if (animal.dob && animal.age) {
          const animalWithSameDay = helpers.date.withSameCalendarDay(animal.dob, animal.age);
          const tempNewAge = helpers.date.ageInYears(animal.dob);

          animal.dobToDisplay = animalWithSameDay ? helpers.date.format(animal.dob) : moment(animal.dob).year();
          animal.ageToDisplay = animalWithSameDay ? helpers.get.age(animal.dob) : tempNewAge;
        } else if (animal.beginBirthPeriod && animal.endBirthPeriod) {
          const animalWithSameDay = helpers.date.withSameCalendarDay(animal.beginBirthPeriod, animal.endBirthPeriod);
          const tempNewAge = helpers.date.ageInYears(animal.beginBirthPeriod);

          animal.dobToDisplay = animalWithSameDay ? helpers.date.format(animal.beginBirthPeriod) : moment(animal.beginBirthPeriod).year();
          animal.ageToDisplay = animalWithSameDay ? helpers.get.age(animal.beginBirthPeriod) : tempNewAge;
        } else {
          animal.dobToDisplay = '-';
          animal.ageToDisplay = '-';
        }

        return animal;
      });
    },

    dobToDisplay: (startDate, endDate) => {
      if (startDate && endDate) {
        return helpers.date.withSameCalendarDay(startDate, endDate) ? helpers.date.format(startDate) : moment(startDate).year();
      }

      return '-';
    },

    getYearDob: (animal) => {
      if (!animal?.beginBirthPeriod) {
        return '';
      }

      if (animal?.beginBirthPeriod && animal.endBirthPeriod && helpers.date.withSameCalendarDay(animal.beginBirthPeriod, animal.endBirthPeriod)) {
        return helpers.date.format(animal.beginBirthPeriod);
      }

      return moment(animal.beginBirthPeriod).year();
    },

    removeAllInvalid: (animalsToChange) => animalsToChange.filter((item) => helpers.tag.isValid(item)),

    removeAllInvalidExtended: (animalsToChange) => {
      animalsToChange
        .filter((item) => !helpers.tag.isValidExtended(item))
        .forEach((row) => {
          if (row.duplicates?.length > 0) {
            row.duplicates.forEach((duplicateIndex) => {
              const duplicateItem = animalsToChange.find((item) => item.index === duplicateIndex);
              const indexToRemove = duplicateItem.duplicates.indexOf(row.index);

              duplicateItem.duplicates.splice(indexToRemove, 1);
            });
          }
        });

      return animalsToChange.filter((item) => helpers.tag.isValidExtended(item));
    }
  },

  batch: {
    getFormatExamples: (speciesId) => {
      if (helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId)) {
        return {
          title: 'formatExamples.batch.title-generic',
          description: 'formatExamples.batch.description-generic'
        };
      }

      if (helpers.species.isDeerId(speciesId)) {
        return {
          title: 'formatExamples.batch.title-generic',
          description: 'formatExamples.batch.description-deer'
        };
      }

      return {
        title: 'formatExamples.batch.title-generic',
        description: ''
      };
    },

    isInvalid: (batch) => batch && batch.valid === 'Invalid',

    isSevenDigitsFormat: (batch) => {
      if (batch && typeof batch === 'string') {

        const batchPrefix = batch.substr(0, 3).toUpperCase();
        const batchNumber = batch.substr(3, 6);

        return batch.length === 9 && (batchPrefix === 'UK0' || batchPrefix === 'UK1') && digitsRegex.test(batchNumber);
      }

      return false;
    },

    isValidBatch: (batch) => {
      if (batch && typeof batch === 'string') {

        const batchPrefix = batch.substring(0, 2).toUpperCase();
        const batchNumber = batch.substring(2, batch.length - 2);

        return batch.length >= 8 && batchPrefix === 'UK' && digitsRegex.test(batchNumber);
      }
      return false;
    },
    isValid: (batch) => batch && batch.valid !== 'Invalid',

    remove: (batchList, batch) => {
      const blankBatchNumber = batch.batchNumber === 'Not supplied';

      return batchList.filter((item) => !(
        (item.batchNumber === batch.batchNumber || (item.batchNumber === '' && blankBatchNumber)) &&
        item.animalTotal === batch.animalTotal
      ));
    }
  },

  config: {
    isOffMoveMaxDays: (fieldName) => fieldName === constants.claConfig.movement_Date_MaxDaysInFuture_OffMove,

    isOnMoveMaxDays: (fieldName) => fieldName === constants.claConfig.movement_Date_MaxDaysInFuture_OnMove,

    isMoveBetweenMaxDays: (fieldName) => fieldName === constants.claConfig.movement_Dates_MaxDaysBetween
  },

  confirmation: {
    getConfirmIdLabel: (submitType, movementRef, paperId) => {
      switch (submitType) {
        case constants.postType.UNDO:
          return 'label.paperIdWithLink';
        case constants.postType.UPDATE:
          return 'label.movementAmendmentSubmittedWithLink';
        case constants.postType.CREATE:
        default:
          return movementRef ? 'label.movementRefWithLink' : (paperId ? 'label.paperIdWithLink' : null);
      }
    },

    getContent: (withErrors, withWarnings, movementRef, validationError) => {
      if (validationError) {
        return null;
      }
      if (withErrors && withWarnings) {
        return 'label.submissionErrorAndWarningContent';
      }
      if (withErrors && !movementRef) {
        return 'label.submissionErrorSuspenseContent';
      }
      if (withErrors) {
        return 'label.submissionErrorContent';
      }
      if (!withErrors && !movementRef) {
        return 'label.submissionPendingCheckLater';
      }
      return withWarnings ? 'label.submissionWarningContent' : null;
    },

    getTitle: (withErrors, withWarnings, movement_Ref, validationError) => {
      if (validationError) {
        return 'label.submissionFailed';
      }
      if (withErrors && withWarnings) {
        return 'label.submissionErrorAndWarning';
      }
      if (withErrors) {
        return 'label.submissionError';
      }
      if (!withErrors && !withWarnings && !movement_Ref) {
        return 'label.submissionPending';
      }
      return withWarnings ? 'label.submissionWarning' : 'label.submissionPaperSuccess';
    }
  },

  cph: {
    width: {
      isHalf: (value) => value === fieldWidthType.HALF,

      isQuarter: (value) => value === fieldWidthType.QUARTER
    },

    isNonCph: (cph) => cph === constants.cph.none,

    knownCPH: {
      isCph: (cph) => cph === constants.option.knowCPHNumber.cph,

      isFsa: (cph) => cph === constants.option.knowCPHNumber.fsa,

      isNo: (cph) => cph === constants.option.knowCPHNumber.no,

      isYes: (cph) => cph === constants.option.knowCPHNumber.yes
    }
  },

  date: {
    addPeriod: (date, value, period) => {
      const dateField = moment.isMoment(date) ? date.clone() : moment(new Date(date));
      return dateField.add(value, period.toLowerCase());
    },

    appendTime: (date, hours, minutes, seconds) => {
      const dateToCheck = moment.isMoment(date) ? date.format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD');

      const dateHours = hours ? helpers.text.zerosPadLeft(hours, 2) : moment().format('HH');
      const dateMinutes = minutes ? helpers.text.zerosPadLeft(minutes, 2) : moment().format('mm');
      const dateSeconds = seconds ? helpers.text.zerosPadLeft(seconds, 2) : moment().format('ss');

      return moment(dateToCheck + ' ' + dateHours + ':' + dateMinutes + ':' + dateSeconds).format();
    },

    ageInYears: (dob) => {
      const startDate = moment(dob);
      const endDate = moment();
      return moment.duration(endDate.diff(startDate)).years();
    },

    dateFormat: (date) => helpers.date.formatYYYYMMDD(date ? new Date(date) : new Date()),

    subDateFormat: (object) => helpers.date.formatYYYYMMDD(object?.date ? new Date(object.date) : new Date()),

    arrivalMax: (date, dateAdvanced = 1) => helpers.date.formatYYYYMMDD(
      helpers.date.momentToJSDate(
        helpers.date.momentMinDate(
          helpers.date.addPeriod(date ? new Date(date) : new Date(), dateAdvanced, constants.period.days),
          helpers.date.todayEndOfDay()
        )
      )
    ),

    dateAhead: (departureDaysAhead) => {
      const daysAHead = new Date();
      daysAHead.setDate(daysAHead.getDate() + departureDaysAhead);

      return helpers.date.formatYYYYMMDD(daysAHead);
    },

    departureMax: (movementType, departureDaysAhead, arriveData = null) => {
      if (helpers.option.movement.isOff(movementType)) {
        return helpers.date.dateAhead(departureDaysAhead);
      }
      return helpers.date.subDateFormat(arriveData);
    },

    differenceInDays: (startDate, endDate) => {
      if (moment.isMoment(startDate) && moment.isMoment(endDate)) {
        return startDate.diff(endDate, constants.period.days);
      }

      return Math.floor((startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24));
    },

    format: (value) => value ? moment(value).format('DD/MM/YYYY') : '-',

    formatForSubmission: (inValue, fullDateTime) => {
      if (inValue) {
        const date = moment.isMoment(inValue) ? inValue : moment(inValue);
        return fullDateTime ? date.format() : date.format('YYYY-MM-DD');
      }

      return '';
    },

    formatJSDate: (date, format) => moment(date).format(format),

    formatJSDatePreformatted: (date, format) => {
      return moment(date, format).format(format);
    },

    formatTime: (value) => value ? moment(value).format('DD/MM/YYYY HH:mm') : '-',

    formatDDMMYYYYtoYYYMMDD: (value) => moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),

    formatYYYYMMDD: (value) => value ? moment(value).format('YYYY-MM-DD') : '-',

    formatDDMMYYYY: (value) => value ? moment(value).format('DD-MM-YYYY') : '-',

    formatYYYYMMDDPreformatted: (value, format) => value ? moment(value, format).format('YYYY-MM-DD') : '-',

    is: {
      afterDate: (date1, date2) => {
        const dateToCheck1 = moment.isMoment(date1) ? date1 : moment(date1).hours(0).minutes(0).seconds(0).milliseconds(0);
        const dateToCheck2 = moment.isMoment(date2) ? date2 : moment(date2).hours(23).minutes(59).seconds(59).milliseconds(999);

        return dateToCheck1.isAfter(dateToCheck2);
      },

      beforeDate: (date1, date2) => {
        const dateToCheck1 = moment.isMoment(date1) ? date1 : moment(date1); //.hours(23).minutes(59).seconds(59).milliseconds(999);
        const dateToCheck2 = moment.isMoment(date2) ? date2 : moment(date2); // .hours(0).minutes(0).seconds(0).milliseconds(0);

        return dateToCheck1.isBefore(dateToCheck2, 'ms');
      },

      betweenMoments: (from, to, test) => {
        if (typeof from !== 'object' || !from || typeof to !== 'object' || !to || typeof test !== 'object' || !test) {
          return false;
        }

        return (test.isBefore(to) && test.isAfter(from)) || test.isSame(from) || test.isSame(to);
      },

      inRange: (inValue, filterValue) => {
        const test = moment(inValue);
        let from;
        let to;

        switch (filterValue) {
          case 'a':
            to = moment();
            from = moment().subtract(12, constants.period.months).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'b':
            to = moment().subtract(12, constants.period.months);
            from = moment().subtract(30, constants.period.months).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'c':
            to = moment().subtract(30, constants.period.months);
            from = moment().subtract(60, constants.period.months).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'd':
            to = moment().subtract(5, constants.period.years);
            from = moment().subtract(6, constants.period.years).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'e':
            to = moment().subtract(6, constants.period.years);
            from = moment().subtract(8, constants.period.years).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'f':
            to = moment().subtract(8, constants.period.years);
            from = moment().subtract(10, constants.period.years).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'g':
            to = moment().subtract(1, constants.period.years);
            from = moment().subtract(2, constants.period.years).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'h':
            to = moment().subtract(2, constants.period.years);
            from = moment().subtract(5, constants.period.years).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'i':
            to = moment().subtract(5, constants.period.years);
            from = moment().subtract(10, constants.period.years).add(1, constants.period.days);
            return helpers.date.is.betweenMoments(from, to, test);
          case 'j':
            return test.isBefore(moment().subtract(10, constants.period.years));
          case 'x':
            return !inValue || inValue === '-';
          default:
            return true;
        }
      },

      inTheFuture: (date, errorContext) => {
        const dateToCheck = moment.isMoment(date) ? date : moment(date);

        if (dateToCheck.isAfter(moment())) {
          if (errorContext && constants.dateRangeErrors[errorContext]) {
            return constants.dateRangeErrors[errorContext];
          }

          return constants.dateRangeErrors.default;
        }

        return null;
      },

      overSevenDaysAgo: (date, errorLabel) => helpers.date.is.xDaysInThePast(date, config.MAX_DAYS_TO_REPORT_DEATH) ? (errorLabel ? errorLabel : 'error.dateGT7day') : null,

      overThreeDaysAgo: (date) => helpers.date.is.xDaysInThePast(date, config.MAX_DAYS_TO_REPORT_MOVEMENT) ? 'error.dateGT3day' : null,

      overAYearAgo: (date) => {
        const dateToCheck = moment.isMoment(date) ? date : moment(date);
        return dateToCheck.isBefore(moment().subtract(1, constants.period.years)) ? 'error.dateGT1year' : '';
      },

      same: (date1, date2) => helpers.date.format(date1) === helpers.date.format(date2),

      sameDayOrNextDay: (date1, date2) => {
        const sameDay = helpers.date.is.same(date1, date2);
        const nextDay = helpers.date.is.same(moment(date2).add(1, 'days'), date1);
        return sameDay || nextDay;
      },

      today: (date) => moment().diff(date, constants.period.days) === 0,

      tomorrow: (date) => moment(moment().format('YYYY-MM-DD') + ' 00:00:00.0000').diff(date, constants.period.days) === -1,

      invalid: (date, format) => {
        if (date !== '') {
          const yearDigitsNumber = format === 'DD/MM/YYYY' ? '4' : '2';

          if (format) {
            const dateFormatPattern = new RegExp(`^(0?[1-9]|[1-2][0-9]|3[01])[/](0?[1-9]|1[0-2])[/](\\d{${yearDigitsNumber}})$`);
            if (!date.match(dateFormatPattern)) {
              return 'error.dateInvalid';
            }
          }

          const dateToCheck = moment.isMoment(date) ? date : moment(date, format);
          return dateToCheck.format() === 'Invalid Date' || !dateToCheck.isValid() ? 'error.dateInvalid' : '';
        }

        return '';
      },

      validCsvDate: (date) => {
        if (date !== '') {
          let isValid = false;

          if (
            (
              !isNaN(date) &&
              (
                (date.length === 4 && parseInt(date) >= 2000) ||
                (date.length === 2 && parseInt(date) >= 20)
              )
            ) ||
            moment(date, 'DD/MM/YYYY').isValid() ||
            moment(date, 'DD/MM/YY').isValid() ||
            moment(date, 'YYYY-MM-DD').isValid() ||
            moment(date, 'YY-MM-DD').isValid()
          ) {
            isValid = true;
          }

          return isValid;
        }

        return false;
      },

      xPeriodAfterDate: (date1, date2, value, period) => {
        const dateToCheck1 = moment.isMoment(date1) ? date1 : moment(date1);
        const dateToCheck2 = moment.isMoment(date2) ? date2 : moment(date2);

        const limit = dateToCheck2.add(value, period.toLowerCase());
        return dateToCheck1.isAfter(limit);
      },

      xPeriodBeforeDate: (date1, date2, value, period) => {
        const dateToCheck1 = moment.isMoment(date1) ? date1 : moment(date1);
        const dateToCheck2 = moment.isMoment(date2) ? date2 : moment(date2);

        const limit = dateToCheck2.subtract(value, period.toLowerCase());
        return dateToCheck1.isBefore(limit);
      },

      xDaysInTheFuture: (date, days) => {
        const dateToCheck = moment.isMoment(date) ? date : moment(date);

        const futureLimit = moment().add(days, constants.period.days);
        return dateToCheck.isAfter(moment()) && dateToCheck.isBefore(futureLimit) ? 'error.dateInsideRange' : null;
      },

      xDaysInTheFutureOverLimit: (date, days) => {
        const dateToCheck = moment.isMoment(date) ? date : moment(date);

        const futureLimit = moment().add(days, constants.period.days);
        return dateToCheck.isSame(futureLimit) || dateToCheck.isAfter(futureLimit) ? 'error.dateOutsideRange' : null;
      },

      xDaysInThePast: (date, days) => {
        const dateToCheck = moment.isMoment(date) ? date : moment(date);

        return moment().diff(dateToCheck, constants.period.days) > days;
      }
    },

    isoToMomentDate: (isoDate) => moment(moment(isoDate).format('YYYY-MM-DD') + ' 00:00:00.0000'),

    momentMinDate: (date1, date2) => moment.min(date1, date2),

    momentToJSDate: (date) => date ? date.toDate() : null,

    momentToISODate: (date) => {
      if (date) {
        if (moment.isMoment(date)) {
          return date.toISOString(true);
        }

        return moment(date).toISOString(true);
      }
      return null;
    },

    now: () => moment().toISOString(true),

    splitStringDate: (stringDate) => (stringDate ? {
      day: moment(stringDate, 'YYYY-MM-DD').date(),
      month: moment(stringDate, 'YYYY-MM-DD').month() + 1,
      year: moment(stringDate, 'YYYY-MM-DD').year()
    } : ''),

    concatToStringDate: (year, month, day) => (year && month && day ? year + '-' + (month?.toString().length === 1 ? '0' + month : month) + '-' + (day?.toString().length === 1 ? '0' + day : day) : ''),

    stringDateToMoment: (date) => date ? moment(date).hours(0).minutes(0).seconds(1) : null,

    subtractPeriod: (date, value, period) => {
      if (moment.isMoment(date)) {
        return date.clone().subtract(value, period);
      }

      return moment(new Date(date)).subtract(value, period.toLowerCase());
    },

    timestamp: () => (new Date()).toISOString().replace('T', '-').replace(/[Z|.]/g, ''),

    toDate: (year, month, day) => moment([year?.length === 2 ? '20' + year : year, month - 1, day]),

    toDateTime: (year, month, day, hours, minutes) => {
      if (hours && minutes) {
        return moment([year?.length === 2 ? '20' + year : year, month - 1, day, hours, minutes, 0, 0]);
      }

      return moment([year?.length === 2 ? '20' + year : year, month - 1, day, moment().hours(), moment().minutes(), 0, 0]);
    },

    todayStartOfDay: () => moment().startOf('day'),

    todayEndOfDay: () => moment().endOf('day'),

    startOfDay: (date) => {
      let dateToReturn;

      if (date === 'today') {
        dateToReturn = moment();
      } else if (moment.isMoment(date)) {
        dateToReturn = date;
      } else {
        dateToReturn = moment(new Date(date));
      }

      return dateToReturn.startOf('day').toISOString(true);
    },

    endOfDay: (date) => {
      let dateToReturn;

      if (date === 'today') {
        dateToReturn = moment();
      } else if (moment.isMoment(date)) {
        dateToReturn = date;
      } else {
        dateToReturn = moment(new Date(date));
      }

      return dateToReturn.endOf('day').toISOString(true);
    },

    bff: (year, mth, day) => {
      const temp = moment().year(year?.length === 2 ? '20' + year : year).month(mth);
      if (day === 'x') {
        temp.date(1).add(1, constants.period.months).subtract(1, constants.period.days);
      } else {
        temp.date(day);
      }
      return temp.format('YYYY-MM-DD');
    },

    withSameCalendarDay: (date1, date2) => {
      return moment(date1).date() === moment(date2).date() && moment(date1).month() === moment(date2).month();
    }
  },

  dateType: {
    isBirth: (dateType) => dateType === constants.dateTypes.birth,

    isDeath: (dateType) => dateType === constants.dateTypes.death,

    isFound: (dateType) => dateType === constants.dateTypes.found,

    isMovement: (dateType) => dateType === constants.dateTypes.movement,

    isTagApplication: (dateType) => dateType === constants.dateTypes.tagApplication
  },

  flags: {
    isDeparture: (flag) => flag === constants.flags.departure,

    isDestination: (flag) => flag === constants.flags.destination,

    isFrom: (flag) => flag === constants.flags.from,

    isSingle: (flag) => flag === constants.flags.single,

    isTo: (flag) => flag === constants.flags.to
  },

  error: {
    addToAnimalObjects: (animalList, errorIndexes, error, objectChildName) => {
      return animalList.map((item, i) => {
        if (objectChildName === 'status') {
          if (parseInt(errorIndexes[1]) === i) {
            item.status = error.value;
          }
        }
        if (objectChildName === 'description') {
          if (parseInt(errorIndexes[1]) === i) {
            item.description = error.value;
          }
        }
        return item;
      });
    },

    amendResponseError: (errors) => {
      const isMatchedCheck = (error) => error?.code === '4076';
      if (errors?.length >= 2 && errors.find((error) => isMatchedCheck(error))) {
        const otherCode = errors.find((error) => !isMatchedCheck(error));
        if (otherCode) {
          return otherCode.code;
        }
      }
      return '';
    },

    isUndoMovementErrorCode: (errors) => {
      const isUndoMoveCheck = (error) => error?.code === '4076';
      if (errors?.length >= 2 && errors.find((error) => isUndoMoveCheck(error))) {
        const errorCodeUndoMove = errors.find((error) => isUndoMoveCheck(error));
        if (errorCodeUndoMove) {
          return errorCodeUndoMove.code;
        }
      }
      return '';
    },

    cfgDepartureDateError: (error, departureDaysAhead) => error !== 'error.dateOutsideRange' ? error : 'error.dateOutsideCfgRange' + departureDaysAhead.toString(),

    consolidate: (messages) => {
      if (!messages || !Array.isArray(messages) || messages?.length === 0) {
        return messages;
      }

      let startMessage = messages;
      // Check the messages with only tag/batch number
      consolidateMessages.forEach((consolidateMessage) => {
        const isMatchedCheck = (item) => item?.code === consolidateMessage.code;
        const matchedList = startMessage.filter((item) => isMatchedCheck(item));
        const noMatchedList = startMessage.filter((item) => !isMatchedCheck(item));

        if (matchedList && matchedList.length > 0) {
          const tagList = matchedList.map((item) => {
            const matched = (consolidateMessage.from).exec(item?.message);
            return matched ? matched[1] : null;
          });
          const tagRange = helpers.holdingRegister.consolidateTagAndBatchNumbers(tagList);
          noMatchedList.push({
            ...matchedList[0],
            message: sprintf(matchedList.length > 1 ? consolidateMessage.to : consolidateMessage.to1, tagRange)
          });
        }
        startMessage = noMatchedList;
      });

      // Check the messages with tag/batch number and 2 other fields
      consolidateMessagesWith3Fields.forEach((consolidateMessage) => {
        const isMatchedCheck = (item) => item?.code === consolidateMessage.code;
        const matchedList = startMessage.filter((item) => isMatchedCheck(item));
        const noMatchedList = startMessage.filter((item) => !isMatchedCheck(item));

        if (matchedList && matchedList.length > 0) {
          const threeFieldsList = [];
          const tagList = matchedList.map((item) => {
            const matched = (consolidateMessage.from).exec(item?.message);
            const species = sprintf('%s%s%s', matched[2], consolidateSeparator, matched[3].replace('.', ''));
            threeFieldsList.push(species);
            return matched ? matched[1] : null;
          });
          const uniqueSpecies = [...new Set(threeFieldsList)];
          uniqueSpecies.forEach((species) => {
            const speciesTagList = [];
            for (let i = 0; i < tagList.length; i++) {
              if (species === threeFieldsList[i]) {
                speciesTagList.push(tagList[i]);
              }
            }
            const tagRange = helpers.holdingRegister.consolidateTagAndBatchNumbers(speciesTagList);
            const speciesArray = species.split(consolidateSeparator);

            noMatchedList.push({
              ...matchedList[0],
              message: sprintf(speciesTagList.length > 1 ? consolidateMessage.to : consolidateMessage.to1, tagRange, speciesArray[0], speciesArray[1])
            });
          });
        }
        startMessage = noMatchedList;
      });
      return startMessage;
    },

    mapping: (message) => {
      if (!message) {
        return message;
      }
      const matched = messageMapping.filter((check) => message.match(check.from)?.length > 0);
      return (matched?.length === 0) ? message : message.replace(matched[0].from, matched[0].to);
    },

    mapCode: (message) => {
      if (!message) {
        return message;
      }
      const matched = messageMapping.filter((check) => message.match(check.from)?.length > 0);
      return (matched?.length === 0) ? 0 : matched[0].code;
    }
  },

  extract: {
    animalsSatisfy: (fciDetail) => (fciDetail.isAllAnimalsFciCompliant || fciDetail.isAllAnimalsFCICompliant)
      ? constants.option.animalsSatisfy.doSatisfy
      : constants.option.animalsSatisfy.doNotSatisfy,

    batches: (data) => {
      if (data?.reviewBatches?.length > 0) {
        return data.reviewBatches;
      }
      if (data?.batches?.length > 0) {
        return data.batches;
      }
      if (data?.movementGroups?.length > 0) {
        const newMovementArray = [];
        data.movementGroups.forEach((item) => {
          item.batches.forEach((batchNumber) => {
            newMovementArray.push(batchNumber);
          });
        });
        if (newMovementArray.length > 0) {
          return newMovementArray;
        }
      }
      return [];
    },

    devices: (data) => {
      if (data?.reviewDevices?.length > 0) {
        return data.reviewDevices.map((item) => ({ tagNumber: item.tagNumber }));
      }
      if (data?.devices?.length > 0) {
        return data.devices.map((item) => ({ tagNumber: item.tagNumber }));
      }
      if (data?.movementGroups?.length > 0) {
        const newMovementArray = [];
        data.movementGroups.forEach((item) => {
          item.devices.forEach((deviceTag) => {
            newMovementArray.push(deviceTag);
          });
        });
        if (newMovementArray.length > 0) {
          return newMovementArray.map((itemMap) => ({ tagNumber: itemMap.tagNumber }));
        }
      }
      return [];
    },

    withdrawalPeriod: (fciDetail, withdrawalPeriods) => {
      if (!fciDetail) {
        return null;
      }
      if (fciDetail.metFCIWithdrawalPeriodCode) {
        return withdrawalPeriods && withdrawalPeriods.find((item) => fciDetail.metFCIWithdrawalPeriodCode === item.code);
      } else if (fciDetail.withdrawalPeriodMetId) {
        return withdrawalPeriods && withdrawalPeriods.find((item) => fciDetail.withdrawalPeriodMetId === item.id);
      }
      return fciDetail.withdrawalPeriodObservedText
        ? withdrawalPeriods && withdrawalPeriods.find((item) => fciDetail.withdrawalPeriodObservedText === item.name)
        : null;
    },

    metFCIWithdrawalPeriod: (fciPeriods, id) => {
      const found = fciPeriods?.length > 0 && id
        ? fciPeriods.find((item) => item.id === id)
        : false;
      return found ? found : id;
    },

    processingFlags: (data) => {
      if (data?.processingFlags?.length > 0) {
        return data.processingFlags;
      }
      if (data?.movementDetailsProcessingFlags?.length > 0) {
        return data.movementDetailsProcessingFlags;
      }
      if (data?.approvedMovementProcessingFlags?.length > 0) {
        return data.approvedMovementProcessingFlags;
      }
      return [];
    },

    processingLabel: (processingItems, id) => {
      const found = processingItems?.length > 0 && id
        ? processingItems.find((item) => item.id === id)
        : false;
      return found ? found.label : id;
    },

    processingName: (processingItems, id) => {
      const found = processingItems?.length > 0 && id
        ? processingItems.find((item) => item.id === id)
        : false;
      return found ? found.name : id;
    },

    processingStatus: (processingItems, id) => {
      const found = processingItems?.length > 0 && id
        ? processingItems.find((item) => (item.processingFlagId === id) || (item.approvedMovementId === id))
        : false;
      return Boolean(found);
    },

    requestBatches: (locationData, data) => {
      if (locationData?.batches?.length > 0) {
        return locationData.batches;
      }
      if (locationData?.reviewBatches?.length > 0) {
        return locationData.reviewBatches;
      }
      return helpers.extract.batches(data);
    },

    requestDevices: (locationData, data) => {
      if (locationData?.devices?.length > 0) {
        return locationData.devices;
      }
      if (locationData?.reviewDevices?.length > 0) {
        return locationData.reviewDevices;
      }
      return helpers.extract.devices(data);
    }
  },

  gender: {
    idToName: (genderId) => genderId ? constants.gender[genderId] : constants.unknown.toLowerCase()
  },

  get: {
    age: (dob, dod) => {
      if (!dob) {
        return '-';
      }

      const dateStart = moment(dob);
      const dateEnd = dod ? moment(dod) : moment();

      const dateOut = [];
      const datesInSameYear = moment.duration(dateEnd.diff(dateStart)).years();
      const intervals = datesInSameYear === 0
        ? [
          constants.period.months,
          constants.period.weeks,
          constants.period.days
        ]
        : [
          constants.period.years,
          constants.period.months,
          constants.period.weeks
        ];

      intervals.forEach((interval) => {
        const dateUnitDifference = dateEnd.diff(dateStart, interval);
        dateEnd.subtract(dateUnitDifference, interval);
        dateOut.push(dateUnitDifference + interval[0]);
      });

      return dateOut.join(' ');
    },

    animalsOnHolding: (cancelToken, params, setModal, setLoadPending, setData, setDataLoaded, animalsToFilterOut, isModal, setTotal) => {
      setLoadPending(true);

      bff
        .get('/animalsOnHolding', {
          cancelToken,
          params: params
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setLoadPending)) {
            let data;

            if (animalsToFilterOut?.length > 0) {
              data = res.data.data.filter((item) => !animalsToFilterOut.find((tag) => item.tagNumber === tag.tagNumber));
            } else {
              data = res.data.data;
            }

            if (isModal) {
              data = data.map((animal) => ({
                ...animal,
                added: false
              }));
            }

            if (typeof setTotal === 'function') {
              setTotal(res.data?.data?.length);
            }

            const finalData = helpers.animal.addDobAndAgeForDisplay(data);
            storeService.session.set.searchResultsAnimalsOnHolding(finalData);
            setData(finalData);

            if (typeof setDataLoaded === 'function') {
              setDataLoaded(true);
            }

            setLoadPending(false);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setLoadPending(false);
            errors.BFF(error, setModal);
          }
        });
    },

    approvedMovement: (cancelToken, inData) => {
      return bff
        .get('/approvedMovement', {
          cancelToken,
          params: {
            id: inData.id,
            requestId: inData.requestId,
            onMovement: helpers.option.recordBy.isByDestination(inData.recordedBy)
          }
        });
    },

    cancelledMovementsListFiltered: (cancelToken, departureHolding, destinationHolding, startTransferDate, endTransferDate, speciesId, filter) => {
      return bff
        .get('/cancelledMovementsList', {
          cancelToken,
          params: {
            departureHolding,
            destinationHolding,
            startTransferDate,
            endTransferDate,
            speciesId,
            filter
          }
        });
    },

    comments: (cph, requestId) => {
      return bff
        .get('/holdingRegisterMovementDetails', {
          params: {
            cph,
            requestId
          }
        });
    },

    holdingDetails: ({ cancelToken, cph, queryKeeper }) => helpers.get.searchHoldingDetails(cancelToken, { cph, queryKeeper }),

    holdingAddress: (currentCPH) => {
      return helpers.get.searchHoldingDetails(null, {
        cph: currentCPH,
        queryKeeper: true
      })
        .then(({ data }) => {
          if (data) {
            return data.data[0];
          }

          return {
            address: {
              address1: constants.address.empty
            }
          };
        });
    },

    holdingTypes: (setModal, source, setLoadPending, setHoldingTypes) => {
      setLoadPending(true);

      bff
        .get('/holdingTypes', {
          cancelToken: source.token,
          params: { nameOnly: true }
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setLoadPending)) {
            if (typeof setHoldingTypes === 'function') {
              const typeList = [{ name: 'Select Holding Type', value: '' }].concat(res.data);
              setHoldingTypes(typeList);
            }

            setLoadPending(false);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setLoadPending(false);
            errors.BFF(error, setModal);
          }
        });
    },

    holdingRegistersMovementDetails: (cancelToken, cph, requestId) => {
      return bff
        .get('/holdingRegisterMovementDetails', {
          cancelToken,
          params: {
            cph,
            requestId
          }
        });
    },

    holdingsInBusiness: (cancelToken, cph) => {
      return bff
        .get('/holdingsInBusiness', {
          cancelToken,
          params: {
            cph
          }
        });
    },

    searchHoldingKeeper: (cancelToken, params) => bff.get('/searchHoldingKeeper', { cancelToken, params }),

    metFciWithdrawalPeriods: (cancelToken) => {
      return bff
        .get('/metFCIWithdrawalPeriods', {
          cancelToken
        });
    },

    reviewStatus: (cancelToken, documentRef) => {
      return bff
        .get('/reviewStatus', {
          cancelToken,
          params: {
            documentRef
          }
        });
    },

    paperMovements: (id) => {
      return bff
        .get('/paperMovements', {
          params: {
            id
          }
        });
    },

    processingFlags: (cancelToken, requestTypeId, speciesId) => {
      return bff
        .get('/processingFlags', {
          cancelToken,
          params: {
            requestTypeId,
            speciesId
          }
        });
    },

    request: (cph, requestId, requestType) => {
      return bff
        .get('/request', {
          params: {
            cph,
            requestId,
            requestType
          }
        });
    },

    requestByIdAndType: (cancelToken, requestId, requestType) => {
      return bff
        .get('/request', {
          cancelToken,
          params: {
            requestId,
            requestType
          }
        });
    },

    requests: (cancelToken, requestId) => {
      return bff
        .get('/requests', {
          cancelToken,
          params: {
            requestId
          }
        });
    },

    reviewHoldingMovements: (cancelToken, requestId) => {
      return bff
        .get('/reviewHoldingMovements', {
          cancelToken,
          params: {
            id: requestId
          }
        });
    },

    searchHoldingDetails: (cancelToken, params) => bff.get('/holdingDetails', { cancelToken, params }),

    searchHolding: (cancelToken, params) => bff.get('/searchHolding', { cancelToken, params }),

    undoRequest: (cancelToken, requestId) => {
      return bff
        .get('/undoRequest', {
          cancelToken,
          params: {
            id: requestId
          }
        });
    }
  },

  holdingRegister: {
    consolidateTagAndBatchNumbers: (tagList) => {
      if (!tagList || !isArray(tagList) || tagList?.length === 0) {
        return '';
      }
      const orderedArray = tagList;
      const getContent = (fields) => fields[1] === fields[2] ? sprintf('%s %s', fields[0], fields[2]) : sprintf('%s %s - %s', fields[0], fields[2], fields[1]);
      const copyBuffer = (fields) => [fields[0], fields[1], fields[1]]; // [batchNumber, currentTagNumber, firstTagNumber]
      const updateEndNumber = (buffer, endNumber) => [buffer[0], endNumber, buffer[2]];
      const emptyBuffer = [null, null, null]; // [batchNumber, currentTagNumber, firstTagNumber]
      const tagRangesArray = [];
      let oldBuffer = emptyBuffer;
      const oldBatch = {};

      orderedArray.forEach((element) => {
        const fields = element.split(' ');

        if (fields.length === 2) { // tag number
          if (oldBuffer[0] === null) { // without previous flock number
            oldBuffer = copyBuffer(fields); // assign the old buffer is the current tag number
          } else if (oldBuffer[0] === fields[0] && (parseInt(oldBuffer[1]) + 1) === parseInt(fields[1])) { // check same flock & number as subsequence as previous
            oldBuffer = updateEndNumber(oldBuffer, fields[1]); // update the end number
          } else {
            tagRangesArray.push(getContent(oldBuffer)); // save the old buffer to array
            oldBuffer = copyBuffer(fields); // assign the old buffer is the current tag number
          }
        } else if (fields.length === 1) {
          if (!oldBatch[element]) {
            oldBatch[element] = 1;
          } else {
            oldBatch[element]++;
          }
        } else {
          if (oldBuffer[0] !== null) { // check any last field in the buffer to be saved
            tagRangesArray.push(getContent(oldBuffer)); // save the old buffer to array
          }
          tagRangesArray.push(element); // save the non-tag number field
          oldBuffer = emptyBuffer; // clear the old buffer
        }
      });

      if (oldBuffer[0] !== null) { // check any last field in the buffer to be saved
        tagRangesArray.push(getContent(oldBuffer)); // save the old buffer to array
      }
      const batchKeys = Object.keys(oldBatch);
      if (batchKeys.length > 0) {
        batchKeys.forEach((key) => {
          tagRangesArray.push(sprintf('%s x %d', key, oldBatch[key]));
        });
      }

      return tagRangesArray.join(', ');
    },

    getReference: () => {
      const sessionHolding = storeService.session.get.holding();
      const sessionSpecies = storeService.session.get.species();
      const sessionHoldingRegister = storeService.session.get.holdingRegister();

      const [fromDate, toDate] = sessionHoldingRegister
        ? [sessionHoldingRegister.fromDate, sessionHoldingRegister.toDate]
        : [helpers.holdingRegister.initFromDate(), helpers.holdingRegister.initToDate()];

      return {
        cph: sessionHolding ? sessionHolding.value : null,
        speciesId: sessionSpecies ? sessionSpecies.id : null,
        fromDate,
        toDate
      };
    },

    downloadPdfLink: (params) => Boolean(
      (
        !helpers.species.isCattleId(params.speciesId) &&
        params.movementId &&
        params.requestId &&
        params.requestTypeId &&
        !helpers.cph.isNonCph(params.destinationHolding)
      ) ||
      (
        params.isOffMove &&
        helpers.cph.isNonCph(params.destinationHolding) &&
        helpers.option.requestType.isMovement(params.requestTypeId)
      )
    ),

    download: (setModal, params) => {
      if (params.setPending) {
        params.setPending(true);
      }

      bff
        .get('/holdingRegisterDownload', {
          params: {
            eventTypes: params.eventTypes.map((type) => type.toLowerCase()),
            cph: params.cph,
            speciesId: params.speciesId,
            fromDate: params.fromDate ? moment(params.fromDate).format('YYYY-MM-DD') : moment().subtract(1, constants.period.days).format(),
            toDate: params.toDate ? moment(params.toDate).add(1, constants.period.days).format('YYYY-MM-DD') : moment().add(1, constants.period.days).format()
          }
        })
        .then((res) => {
          let filename = 'holdingRegister';

          if (params.areas?.length === 1) {
            filename += '-' + params.areas[0];
          }

          helpers.openFileFromResponse(res.data, filename, 'ods');

          if (params.setPending) {
            params.setPending(false);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            if (params.setPending) {
              params.setPending(false);
            }
            errors.BFF(error, setModal);
          }
        });
    },

    initFromDate: () => helpers.date.formatForSubmission(helpers.date.todayStartOfDay().subtract(6, constants.period.months)),

    initToDate: () => helpers.date.formatForSubmission(helpers.date.todayEndOfDay()),

    saveDates: (fromDate, toDate) => storeService.session.set.holdingRegister({
      fromDate: fromDate ? fromDate : null,
      toDate: toDate ? toDate : null
    }),

    types: {
      isAdjacentMovement: (movement) => movement === constants.holdingRegister.type.adjacentMovements,

      isAnnualInventory: (movement) => movement === constants.holdingRegister.type.annualInventory,

      isBirths: (movement) => movement === constants.holdingRegister.type.births,

      isDeaths: (movement) => movement === constants.holdingRegister.type.deaths,

      isDownload: (movement) => movement === constants.holdingRegister.type.download,

      isMovementOff: (movement) => movement === constants.holdingRegister.type.movementsOff,

      isMovementOn: (movement) => movement === constants.holdingRegister.type.movementsOn,

      isTaggingApplications: (movement) => movement === constants.holdingRegister.type.tagApplications,

      isTaggingReplacements: (movement) => movement === constants.holdingRegister.type.tagReplacements
    }
  },

  labelPosition: {
    isAbove: (position) => position === constants.labelPosition.above,

    isInline: (position) => position === constants.labelPosition.inline
  },

  lookup: {
    transportedBy: (transporterTypeId) => {
      switch (transporterTypeId) {
        case constants.option.transporterTypeId.departureKeeperType:
          return constants.option.transporter.departureKeeper;
        case constants.option.transporterTypeId.destinationKeeperType:
          return constants.option.transporter.destinationKeeper;
        case constants.option.transporterTypeId.haulierType:
          return constants.option.transporter.haulier;
        default:
          return null;
      }
    },

    transporterType: (transporterType) => {
      switch (transporterType) {
        case constants.transporter.DEP:
        case constants.transporter.departureKeeper:
          return constants.option.transporter.departureKeeper;
        case constants.transporter.DEST:
        case constants.transporter.destinationKeeper:
          return constants.option.transporter.destinationKeeper;
        case constants.transporter.HAULIER:
        case constants.transporter.haulier:
          return constants.option.transporter.haulier;
        default:
          return null;
      }
    }
  },

  messageStatus: {
    isDefault: (value) => value === constants.option.messageStatus.default,

    isUnconfirmed: (value) => value === constants.option.messageStatus.unconfirmed,

    isUnknown: (value) => value === constants.option.messageStatus.unknown
  },

  option: {
    animals: {
      doSatisfy: (option) => option === constants.option.animalsSatisfy.doSatisfy,

      doNotSatisfy: (option) => option === constants.option.animalsSatisfy.doNotSatisfy
    },

    embryo: {
      isTransfer: (embryo) => embryo === constants.option.embryo.yes
    },

    fci: {
      doRecord: (record) => record === constants.option.recordFCI.yes,

      doNotRecord: (record) => record === constants.option.recordFCI.no
    },

    identificationType: {
      isBreedingTags: (type) => type === constants.option.identification.breedingTags,

      isSlaughterTags: (type) => type === constants.option.identification.slaughterTags
    },

    movement: {
      isCancelled: (movementType) => movementType === constants.movementTypeSearch.cancelled,

      isCrossBorder: (movementType) => movementType === constants.movementTypeSearch.xb,

      isCrossBorderUnsupportedRequestType: (value) => typeof value === 'number' ? constants.movement.crossBorderUnsupportedRequestType.includes(value) : false,

      isExempt: (inValue) => typeof inValue !== 'string' ? false : inValue === constants.option.exemption.yes,

      isMoveToCommonGround: (movementType, otherHolding) => helpers.option.movement.isOff(movementType) && typeof otherHolding === 'string' && otherHolding?.substring(0, 2) === '00',

      isNew: (inValue) => typeof inValue !== 'string' ? false : inValue === constants.option.movement.newMovement,

      isOff: (inValue) => typeof inValue !== 'string' ? false : inValue === constants.option.movement.off,

      isOn: (inValue) => typeof inValue !== 'string' ? false : inValue === constants.option.movement.on,

      isPermitMove: (inValue) => typeof inValue !== 'string' ? false : inValue === constants.option.movement.permit,

      isReview: (inValue) => typeof inValue !== 'string' ? false : inValue === constants.option.movement.reviewMovement,

      isStandard: (movementType) => movementType === constants.movementTypeSearch.standard
    },

    movementReviewStatus: {
      hasBeenConfirmed: (reviewStatus) => reviewStatus === 'ReviewedAndAccepted',

      hasBeenPartiallyConfirmed: (reviewStatus) => reviewStatus === 'ReviewedAndPartiallyAccepted',

      hasBeenRejected: (reviewStatus) => reviewStatus === 'ReviewedAndRejected',

      hasNotBeenReviewed: (reviewStatus) => reviewStatus === 'NotReviewed',

      isCancelled: (reviewStatus) => reviewStatus === 'Cancelled',

      inNotRequired: (reviewStatus) => reviewStatus === 'NotRequired',

      isPending: (reviewStatus) => reviewStatus === 'Pending'
    },

    recordBy: {
      isByDeparture: (recorder) => recorder === constants.recordedBy.departure,

      isByDestination: (recorder) => recorder === constants.recordedBy.destination
    },

    reportType: {
      isMissing: (reportType) => reportType === constants.option.missingOrFound.missing,

      isFound: (reportType) => reportType === constants.option.missingOrFound.found
    },

    requestType: {
      isAdjacentMovement: (requestType) => requestType === constants.option.requestType.adjacentMovement,

      isAnimalsAdded: (requestType) => requestType === constants.option.requestType.animalsAdded,

      isAnnualInventoryCreate: (requestType) => requestType === constants.option.requestType.annualInventoryCreate,

      isAnnualInventoryDelete: (requestType) => requestType === constants.option.requestType.annualInventoryDelete,

      isBirth: (requestType) => requestType === constants.option.requestType.birth,

      isCorrectedMovement: (requestType) => requestType === constants.option.requestType.correctTransfer,

      isDeath: (requestType) => requestType === constants.option.requestType.death,

      isHoldingProductionTypeCreate: (requestType) => requestType === constants.option.requestType.holdingProductionTypeCreate,

      isHoldingProductionTypeDelete: (requestType) => requestType === constants.option.requestType.holdingProductionTypeDelete,

      isMovement: (requestType) => requestType === constants.option.requestType.movement,

      isMovementHandshake: (requestType) => requestType === constants.option.requestType.movementHandshake,

      isTagApplication: (requestType) => requestType === constants.option.requestType.tagApplication,

      isTagReplacement: (requestType) => requestType === constants.option.requestType.tagReplacement,

      isPaperMovementAdd: (requestType) => requestType === constants.option.requestType.paperMovementAdd,

      isPaperMovementEdit: (requestType) => requestType === constants.option.requestType.paperMovementEdit,

      isUndo: (requestType) => requestType === constants.option.requestType.undo
    },

    selectionMethod: {
      isBatch: (type) => type === constants.option.selectionMethod.batch,

      isHolding: (type) => type === constants.option.selectionMethod.holding,

      isIndividual: (type) => type === constants.option.selectionMethod.individual,

      isManualTagNumbers: (type) => type === constants.option.selectionMethod.manualTagNumbers,

      isTagNumberRange: (type) => type === constants.option.selectionMethod.tagNumberRange,

      isUploadFile: (type) => type === constants.option.selectionMethod.uploadFile
    },

    state: {
      isInvalid: (state) => state === constants.states.invalid,

      isReformatted: (state) => state === constants.states.reformatted,

      isValid: (state) => state === constants.states.valid
    },

    tagMethod: {
      isOnHolding: (tagMethod) => tagMethod === constants.option.tagMethod.onHolding,

      isSelectFromLists: (tagMethod) => tagMethod === constants.option.tagMethod.selectFromLists,

      isTagsManually: (tagMethod) => tagMethod === constants.option.tagMethod.tagsManually
    },

    tagType: {
      isBreeding: (tagType) => tagType === constants.option.tag.breedingTags,

      isIndividual: (tagType) => tagType === constants.option.tag.individualTag,

      isSlaughter: (tagType) => tagType === constants.option.tag.slaughterTags,

      isUpgrade: (tagType) => tagType === constants.option.tag.upgradeTags
    },

    transporter: {
      isDepartureKeeper: (transporter) => transporter === constants.option.transporter.departureKeeper || constants.transporterTypes.departureKeeper.includes(transporter),

      isDestinationKeeper: (transporter) => transporter === constants.option.transporter.destinationKeeper || constants.transporterTypes.destinationKeeper.includes(transporter),

      isHaulier: (transporter) => transporter === constants.option.transporter.haulier || constants.transporterTypes.haulier.includes(transporter)
    }
  },

  postType: {
    isConfirm: (pType) => pType === constants.postType.CONFIRM,

    isCreate: (pType) => pType === constants.postType.CREATE,

    isDelete: (pType) => pType === constants.postType.DELETE,

    isReject: (pType) => pType === constants.postType.REJECT,

    isUndo: (pType) => pType === constants.postType.UNDO,

    isUpdate: (pType) => pType === constants.postType.UPDATE
  },

  redirect: {
    searchByMovementReference: (history, documentRef) => {
      storeService.session.removeAll.searchResults();
      storeService.session.set.activeTabId(constants.tabs.movementRef);
      storeService.session.set.tableFiltersMovements({ movementRef: documentRef });

      history.push(Routing.boSearchMovements);
    },

    searchByPaperId: (history, paperId) => {
      storeService.session.removeAll.searchResults();
      storeService.session.set.activeTabId(constants.tabs.paperId);
      storeService.session.set.tableFiltersMovements({ paperId });

      history.push(Routing.boSearchMovements);
    },

    paperMovement: (history, paperId, state) => {
      history.push(
        Routing.boMovements + paperId,
        state
      );
    }
  },

  response: {
    isError: (value) => value === constants.errorAndWarning.error,

    isValid: (resData, setModal, setPending) => {
      if (!resData || (typeof resData === 'object' && !Array.isArray(resData) && Object.keys(resData).length === 0)) {
        setModal({
          modalTitle: 'error.invalidResponse-title',
          modalMessage: [
            'error.unexpectedError1',
            'error.unexpectedError2'
          ]
        });

        if (setPending && typeof setPending === 'function') {
          setPending(false);
        }

        return false;
      }

      try {
        if (typeof resData === 'object') {
          JSON.stringify(resData);
        } else if (typeof resData === 'string' || typeof resData === 'number') {
          JSON.parse(resData);
        }
      } catch (e) {
        setModal({
          modalTitle: 'error.invalidResponse-title',
          modalMessage: 'error.invalidResponse-message'
        });

        if (setPending && typeof setPending === 'function') {
          setPending(false);
        }

        return false;
      }

      return true;
    },

    isWarning: (value) => value === constants.errorAndWarning.warning
  },

  species: {
    formatName: (speciesName) => speciesName && typeof speciesName === 'string' ? Object.values(constants.species.internalLabel).find((item) => item.toLowerCase() === speciesName.toLowerCase()) : null,

    nameToId: (speciesName) => {
      if (typeof speciesName !== 'string' || !speciesName) {
        return constants.species.id.UNKNOWN;
      }
      const matchSpecies = constants.species.internalList.filter((species) => species.name.toLowerCase() === speciesName.toLowerCase());
      return matchSpecies?.length > 0 ? matchSpecies[0].id : constants.species.id.UNKNOWN;
    },

    idToName: (speciesId) => {
      if (typeof speciesId !== 'number' || !speciesId) {
        return constants.species.internalLabel.UNKNOWN;
      }
      const matchSpecies = constants.species.internalList.filter((species) => (species.id === speciesId));
      return matchSpecies?.length > 0 ? matchSpecies[0].name : constants.species.internalLabel.UNKNOWN;
    },

    isCattleId: (checkSpeciesId) => typeof checkSpeciesId !== 'number' ? false : checkSpeciesId === constants.species.id.CATTLE,

    isSheepId: (checkSpeciesId) => typeof checkSpeciesId !== 'number' ? false : checkSpeciesId === constants.species.id.SHEEP,

    isGoatId: (checkSpeciesId) => typeof checkSpeciesId !== 'number' ? false : checkSpeciesId === constants.species.id.GOATS,

    isPigId: (checkSpeciesId) => typeof checkSpeciesId !== 'number' ? false : checkSpeciesId === constants.species.id.PIGS,

    isDeerId: (checkSpeciesId) => typeof checkSpeciesId !== 'number' ? false : checkSpeciesId === constants.species.id.DEER
  },

  status: {
    isWelshOrScottish: (holding) => holding && holding !== 'UNKNOWN' && (holdingHelper.cph.isWelsh(holding) || holdingHelper.cph.isScottish(holding)),

    isNonEnglishCPHs: (t, data) => {
      if (data &&
        (
          helpers.status.isWelshOrScottish(data.sourceHolding) ||
          helpers.status.isWelshOrScottish(data.destinationHolding) ||
          helpers.status.isWelshOrScottish(data.fromHolding) ||
          helpers.status.isWelshOrScottish(data.toHolding)
        )
      ) {
        return t('label.xb');
      }
      return null;
    },

    isUnknownMove: (t, data) => {
      if (data && (
        (holdingHelper.cph.isUnknownCph(data.sourceHolding)) ||
        (holdingHelper.cph.isUnknownCph(data.destinationHolding)) ||
        (holdingHelper.cph.isUnknownCph(data.fromHolding)) ||
        (holdingHelper.cph.isUnknownCph(data.toHolding)))
      ) {
        return t('label.un');
      }

      return null;
    },

    isPermitMove: (t, data) => {
      if (data && (
        (holdingHelper.cph.isNonCph(data.sourceHolding)) ||
        (holdingHelper.cph.isNonCph(data.destinationHolding)) ||
        (holdingHelper.cph.isNonCph(data.fromHolding)) ||
        (holdingHelper.cph.isNonCph(data.toHolding)))
      ) {
        return t('label.pm');
      }

      return null;
    },

    isCancelledMove: (t, data) => {
      if (data?.cancelledMove) {
        switch (data?.requestTypeUndone) {
          case constants.undoRequestTypes.correction:
            return t('label.ca');
          case constants.undoRequestTypes.review:
            return t('label.cr');
          case constants.undoRequestTypes.transfer:
            return data?.movementReferenceCorrected ? t('label.cma') : t('label.cm');
          default:
            return null;
        }
      }
      return null;
    },

    getType: (t, data) =>
      helpers.status.isCancelledMove(t, data) ??
      helpers.status.isNonEnglishCPHs(t, data) ??
      helpers.status.isPermitMove(t, data) ??
      helpers.status.isUnknownMove(t, data) ??
      (data ? t(data.confirmed ? 'label.yes' : 'label.no') : null)
  },

  tab: {
    isMovementRange: (tab) => tab === constants.tabs.movementRange,

    isMovementRef: (tab) => tab === constants.tabs.movementRef,

    isPaperId: (tab) => tab === constants.tabs.paperId
  },

  tag: {
    canLink: (inValue) => inValue !== null && inValue !== '-',

    convertToRfid: (tag) => tag.replace(/ /g, '').replace('UK', '0826').trim(),

    formatNumber: (inValue, theLength) => {
      if (typeof inValue !== 'number' || !inValue || !theLength || theLength <= 0) {
        return null;
      }
      let outValue = inValue.toString();
      while (outValue?.length < theLength) {
        outValue = '0' + outValue;
      }
      return outValue;
    },

    getColour: (requestStatus) => {
      switch (requestStatus) {
        case constants.status.success:
          return 'green';
        case constants.status.completed_warning:
        case constants.status.warnings:
          return 'yellow';
        case constants.status.cancelled:
        case constants.status.completed_error:
        case constants.status.processErrors:
        case constants.status.errors:
          return 'red';
        case constants.status.claPending:
        case constants.status.pending:
        case constants.status.processing:
          return 'black';
        default:
          return '';
      }
    },

    getFormatExamples: (speciesId) => {
      return {
        title: 'formatExamples.tag.title-' + speciesId,
        description: 'formatExamples.tag.description-' + speciesId
      };
    },

    getInvalidField: (row) => {
      if (row.invalid?.length > 1) {
        return '-entry';
      }
      if (row.invalid?.includes('tagNumber')) {
        return '-tagNumber';
      }
      if (row.invalid?.includes('breedName')) {
        return '-breed';
      }
      if (row.invalid?.includes('genderName')) {
        return '-gender';
      }
      if (row.invalid?.includes('dob')) {
        return '-dob';
      }
      return '';
    },

    hasNonEidTagsSheep: (speciesId, data) => {
      const tagsToCheck = [];
      const extractBatchNumber = (tag) => tag ? tag.trim().substring(0, tag.indexOf(' ')) : null;

      if (helpers.species.isSheepId(speciesId)) {
        data.forEach((tag) => {
          if (helpers.tag.isValid(tag)) {
            if (tag.tagNumber) {
              tagsToCheck.push(extractBatchNumber(tag.tagNumber));
            } else {
              if (tag.newTag !== null && !tag.newBatch) {
                tagsToCheck.push(extractBatchNumber(tag.newTag?.tag));
              }
              if (tag.oldTag !== null && !tag.oldBatch) {
                tagsToCheck.push(extractBatchNumber(tag.oldTag?.tag));
              }
            }
          }
        });
      }

      return tagsToCheck.some((tag) => tag?.substr(0, 2).toUpperCase() === 'UK' && tag?.length === 8);
    },

    isInvalid: (tag) => tag?.valid === constants.tag.invalid,

    isInvalidExtended: (tag) => tag?.invalid?.length > 0,

    isNonEid: (tag) => {
      if (tag?.tagNumber) {
        const tagToCheck = tag.tagNumber.trim().substring(0, tag.tagNumber.indexOf(' '));

        return tagToCheck.substr(0, 2).toUpperCase() === 'UK' && tagToCheck.substr(2)?.length === 6;
      }

      return false;
    },

    isValid: (tag) => tag?.valid !== constants.tag.invalid,

    isValidExtended: (tag) => tag?.invalid?.length ? tag.invalid.length === 0 : true,

    isValidTagNumber: (tag) => {
      if (tag && typeof tag === 'string') {
        if (tag.length < 3) {
          return false;
        }

        if (tag.substr(0, 3).toUpperCase() === 'UK0') {
          return tag.length > 14;
        }
        return tag.length > 13;
      }

      return false;
    },

    remove: (tagList, tag) => tagList.filter((item) => !(item.tagNumber === tag.tagNumber)),

    trim: (tag) => tag.replace(/\s\s+/g, ' ').trim(),

    zerosPadLeft: (speciesId, batch, tag, strLength) => {
      if (
        tag &&
        (
          !helpers.species.isGoatId(speciesId) ||
          (
            helpers.species.isGoatId(speciesId) &&
            batch !== '' &&
            helpers.batch.isSevenDigitsFormat(batch)
          )
        )
      ) {
        return helpers.text.zerosPadLeft(tag, strLength ? strLength : parseInt(config.WIDTH_START_NUMBER));
      }

      return tag;
    }
  },

  text: {
    zerosPadLeft: (string, length) => {
      if (String(string)?.length > length) {
        return string;
      }

      const newString = '0'.repeat(length) + string;

      return newString.substr(newString?.length - length, length);
    },

    pluralCheck: (inValue, text) => (inValue === 1) ? text : text + '-plural',

    sprintf: (theString, argumentsArray) => {
      if (theString !== null && theString !== '' && argumentsArray?.length > 0) {
        const regex = /%s/;
        const replacement = (p, c) => p.replace(regex, c);
        return argumentsArray.reduce(replacement, theString);
      }
      return theString;
    },

    sentenceCase: (text) => {
      if (typeof text !== 'string' || text === '') {
        return '';
      }
      const result = text.replace(/\s/g, '').match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join(' ');
      return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    },

    camelCase: (text) => {
      if (typeof text !== 'string' || text === '') {
        return '';
      }
      const result = text.replace(/\s/g, '').match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join(' ');
      return result.charAt(0).toLowerCase() + result.slice(1);
    },

    pascalCase: (text) => {
      if (typeof text !== 'string' || text === '') {
        return '';
      }
      const result = text.replace(/\s/g, '').match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join(' ');
      return result.charAt(0).toUpperCase() + result.slice(1);
    },

    getContentsLink: (t, text, onClick, href) => {
      const contentsMatch = t(text).match(new RegExp('<link>(.*)</link>'));

      if (contentsMatch) {
        const stringParts = t(text).split(contentsMatch[0]);
        const returnArray = [
          <span
            dangerouslySetInnerHTML={{ __html: stringParts[0] }}
            key="stringPart0"
          />,
          '',
          <span
            dangerouslySetInnerHTML={{ __html: stringParts[1] }}
            key="stringPart2"
          />
        ];

        if (onClick) {
          returnArray[1] = (
            <Button
              buttonType="link"
              classNames={['inTextLink']}
              key="stringPart1"
              label={contentsMatch[1]}
              onClick={onClick}
            />
          );
        } else if (href) {
          returnArray[1] = (
            <a
              href={href}
              key="stringPart1"
              rel="noopener noreferrer"
              target="_blank"
            >
              {contentsMatch[1]}
            </a>
          );
        }

        return returnArray;
      }

      return [
        <span
          dangerouslySetInnerHTML={{ __html: t(text) }}
          key="stringPart0"
        />
      ];
    }
  },

  type: {
    isBoolean: (value) => typeof value === 'boolean'
  },

  undoRequest: {
    isCorrection: (request) => request === constants.undoRequestTypes.correction,

    isReview: (request) => request === constants.undoRequestTypes.review,

    isTransfer: (request) => request === constants.undoRequestTypes.transfer
  },

  userRole: {
    isAbattoir: (role) => role === userRoleType.ABATTOIR,

    isAICentre: (role) => role === userRoleType.AICENTRE,

    isAssembly: (role) => role === userRoleType.ASSEMBLY,

    isBackOffice: (role) => role === userRoleType.BACKOFFICE,

    isCollection: (role) => role === userRoleType.COLLECTION,

    isCommon: (role) => role === userRoleType.COMMON,

    isGeneric: (role) => role === userRoleType.GENERIC,

    isKeeper: (role) => role === userRoleType.KEEPER,

    isMarket: (role) => role === userRoleType.MARKET,

    isOther: (role) => role === userRoleType.OTHER,

    isPort: (role) => role === userRoleType.PORT,

    isQuarantine: (role) => role === userRoleType.QUARANTINE,

    isShow: (role) => role === userRoleType.SHOW,

    isSlaughterMarket: (role) => role === userRoleType.SLAUGHTERMARKET,

    isStoreMarket: (role) => role === userRoleType.STOREMARKET,

    isUnknown: (role) => role === userRoleType.UNKNOWN
  },

  validate: {
    cphNumber: (cph) => (/^\d{2}\/\d{3}\/\d{4}$/).test(cph),

    fsaNumber: (fsa) => (/^\d{4}$/).test(fsa),

    tagNumbers: (species, batchNumbers, tagNumbers) => {
      return bff
        .post('/validate', {
          species,
          batchNumbers,
          tagNumbers
        });
    }
  },

  checkObjectEquality: (object1, object2) => {
    const object1Copy = cloneDeep(object1);
    const object2Copy = cloneDeep(object2);

    mergeWith(object1Copy, object2Copy, (objectValue, sourceValue, key) => {
      if (!(isEqual(objectValue, sourceValue)) && (Object(objectValue) !== objectValue)) {
        // eslint-disable-next-line no-console
        console.log('-----------------------------------------------');
        // eslint-disable-next-line no-console
        console.log(key);
        // eslint-disable-next-line no-console
        console.log('    Expected:', sourceValue);
        // eslint-disable-next-line no-console
        console.log('    Actual:', objectValue);
      }
    });
  },

  movementReferenceByRequest: (setModal, requestId, requestType, setData, generatePDF) => {
    bff
      .get('/request', { params: { requestId, requestType } })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal) && res.data.movementDocument) {
          setData(res.data.movementDocument.movementDocumentRef);
          generatePDF(res.data.movementDocument.movementDocumentRef);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });
  },

  dropdownSearch: (options) => {
    let optionsList;

    if (options[1] && options[1].type === 'group' && options[1].items?.length > 0) {
      optionsList = options[1].items;
    } else if (options[0] && options[0].type === 'group' && options[0].items?.length > 0) {
      optionsList = options[0].items;
    } else {
      optionsList = options;
    }

    return (value) => {
      if (!value?.length) {
        return options;
      }

      return optionsList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    };
  },

  generateUUID: () => {
    let result, i, j;
    result = '';
    for (j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result += '-';
      }
      i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
      result += i;
    }
    return result;
  },

  openFileFromResponse: (data, reportType, fileType) => {
    const datestamp = helpers.date.timestamp();
    const link = document.createElement('a');
    const matchedMimeType = constants.mimeList.find((mimeItem) => mimeItem.extension === fileType);
    const mimeType = matchedMimeType ? matchedMimeType.mimeType : 'text/plain';

    link.href = sprintf('data:%s;base64,', mimeType) + data;
    link.download = sprintf('%s_%s.%s', reportType, datestamp, fileType);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  writeWorkbookToFile: (workbook, fileName, fileType) => XLSX.writeFile(workbook, `${fileName}.${fileType}`, { compression: true }),

  generateWorkbook: (data) => {
    const workbook = XLSX.utils.book_new();
    Object.keys(data).forEach((key) => {
      const keyData = data[key] ? data[key] : [''];
      const worksheet = XLSX.utils.json_to_sheet(keyData);
      const arrayWidths = [];
      Object.keys(keyData[0]).forEach((header) => {
        const arrayOfWordLengths = [header?.length, ...keyData.map((keyDataItem) => keyDataItem[header]?.length)].filter(Number);
        arrayWidths.push({ wch: Math.max(...arrayOfWordLengths) + 10 });
      });
      worksheet['!cols'] = arrayWidths;
      XLSX.utils.book_append_sheet(workbook, worksheet, key.split('_').join(' '));
    });
    return workbook;
  },

  generateAnimalWorkbook: (holding, speciesName, timeStamp, data) => {
    const workbook = XLSX.utils.book_new();
    const workSheetData = [];
    const address = helpers.address.format.addressFull(holding.address).filter(Boolean).join(',');
    const formattedAddress = address === 'label.cphNotFound' ? 'No address available' : address;
    workSheetData.push(['Date and time of download', timeStamp]);
    workSheetData.push(['Holding', holding.value]);
    workSheetData.push(['Address', formattedAddress]);
    workSheetData.push(['Species', speciesName]);
    workSheetData.push(['']);
    workSheetData.push(['Tag number', 'Breed', 'Gender', 'Year / DOB', 'Age']);
    const capitalizeFirstCharacter = (str) => str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str;

    data.forEach((item) => {
      let age = '-',
        dob = 'Unknown';
      if (item.beginBirthPeriod) {
        const sameDay = helpers.date.withSameCalendarDay(item.beginBirthPeriod, item.endBirthPeriod);
        dob = sameDay ? helpers.date.format(item.beginBirthPeriod) : moment(item.beginBirthPeriod).year();
        if (sameDay) {
          age = helpers.get.age(item.beginBirthPeriod);
        } else {
          const tempAge = helpers.date.ageInYears(item.beginBirthPeriod);
          age = tempAge === 0 ? 'Under 1y' : tempAge + 'y';
        }
      }
      workSheetData.push([
        item.tagNumber,
        item.breedName ?? 'Unknown',
        capitalizeFirstCharacter(helpers.gender.idToName(item.genderId)),
        dob,
        age
      ]);
    });

    workSheetData.push(['Total animals: ' + data.length]);
    const ws = XLSX.utils.aoa_to_sheet(workSheetData);
    XLSX.utils.book_append_sheet(workbook, ws, timeStamp);
    return workbook;
  },

  replaceNull: (inValue) => {
    return inValue ? inValue : '';
  },

  scrollToTop: (element) => {
    if (element) {
      element.scrollTop = 0;
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },

  setCaretPosition: (ctrl, pos) => {
    if (ctrl.setSelectionRange) { // Modern browsers
      setTimeout(() => {
        ctrl.focus();
        ctrl.setSelectionRange(pos, pos);
      }, 10);
    } else if (ctrl.createTextRange) { // IE8 and below
      const range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  },

  sortArrayOfObjects: (arr, property, direction) => arr.sort(sortByProperty(property, direction)),

  sortTags: (tags) => {
    const list = tags.map((item) => {
      const rec = item.split(' ');
      if (rec.length < 2) {
        rec[1] = '0';
        rec[2] = 0;
      } else {
        rec[2] = parseInt(rec[1]);
      }
      rec[3] = item;
      return rec;
    });

    list.sort((a, b) => {
      const o1 = a[0];
      const o2 = b[0];

      const p1 = a[2];
      const p2 = b[2];

      if (o1 < o2) {
        return -1;
      }
      if (o1 > o2) {
        return 1;
      }
      if (p1 < p2) {
        return -1;
      }
      if (p1 > p2) {
        return 1;
      }
      return 0;
    });

    const arr = list.map((row) => {
      return row[3];
    });

    return arr;
  },

  resetDropdownFocusState: () => {
    const interval = setInterval(() => {
      const highlightedOptions = document.getElementsByClassName('is-highlighted');

      if (highlightedOptions) {
        clearInterval(interval);
        for (const el of highlightedOptions) {
          el.classList.remove('is-highlighted');
        }
      }
    }, 10);
  },

  getUserRole: (roles) => {
    if (roles.some((r) => boRoles.includes(r.toLowerCase()))) {
      return userRoleType.BACKOFFICE;
    }
    return roles.some((r) => rcvsRoles.includes(r.toLowerCase())) ? userRoleType.RCVSVET : userRoleType.GENERIC;
  }

};

export default helpers;
